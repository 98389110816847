var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"app-change-password"},[_c('a-form-model',{ref:"form",staticClass:"form-link",attrs:{"model":_vm.form}},[_c('a-form-model-item',{attrs:{"required":"","label":"原密码","prop":"old_password","rules":[
          {
            required: true,
            message: '密码必填'
          },
          {
            min: 6,
            message: '密码至少6个字符'
          },
          {
            max: 32,
            message: '密码最多32个字符'
          }
        ]}},[_c('a-input-password',{model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}})],1),_c('a-form-model-item',{attrs:{"required":"","label":"新密码","prop":"password","rules":[
          {
            required: true,
            message: '密码必填'
          },
          {
            min: 6,
            message: '密码至少6个字符'
          },
          {
            max: 32,
            message: '密码最多32个字符'
          }
        ]}},[_c('a-input-password',{attrs:{"type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('a-button',{attrs:{"type":"primary","block":"","size":"large"},on:{"click":_vm.submit}},[_vm._v("提交")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }